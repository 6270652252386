import React from "react";
import {
  Switch,
  Routes,
  Route,
  withRouter,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import Homepage from "../components/Homepage";
import Onboard from "../components/Onboard";
import AuthForm from "../components/AuthForm/AuthForm";
import Aboutpage from "../components/About/Aboutpage";
import Privacy from "../components/Privacy";
import {
  authUser,
  googleUrl,
  googleUser,
  forgotPassword,
  resetPassword,
} from "../store/actions/auth";
import { removeError } from "../store/actions/errors";
import MentorPage from "../components/MentorPage";
import Pathway from "../components/Pathway";
import Assessment from "../components/Assessment/Assessment";
import Error from "../components/Error";
import Waitlist from "../components/Waitlist";
import Dashboard from "../components/Dashboard/Dashboard";
import EnterpriseLanding from "../components/Enterprise/EnterpriseLandingPage";
import Onboarding from "../components/Dashboard_v1_business/Onboard.jsx";
import OnboardingUser from "../components/Dashbord_v1_user/Onboard";
import { getResource, getUser } from "../store/actions/users";
import ShareLearningPage from "../components/ShareLearningPage/ShareLearningPage";
import Tapit from "../components/Tapit.jsx";

const Main = (props) => {
  const {
    authUser,
    errors,
    removeError,
    currentUser,
    googleUrl,
    authUrl,
    googleUser,
    forgotPassword,
    resetPassword,
    currentUserResources,
    getUser,
  } = props;
  const location = useLocation();
  return (
    <div className="container">
      <Switch location={location} key={location.pathname}>
        <Route
          exact
          path="/"
          render={(props) => (
            <Homepage
              currentUser={currentUser}
              currentUserResources={currentUserResources}
              onAuth={googleUser}
              removeError={removeError}
              fetchUser={getUser}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/pathway"
          render={(props) => <Dashboard currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/about"
          render={(props) => <Aboutpage currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/about/privacy"
          render={(props) => <Privacy currentUser={currentUser} {...props} />}
        />

        <Route
          exact
          path="/pathway/:name"
          render={(props) => <Pathway currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/waitlist"
          render={(props) => <Waitlist currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/assess/:name"
          render={(props) => (
            <Assessment currentUser={currentUser} {...props} />
          )}
        />

        <Route
          exact
          path="/mentor/register"
          render={(props) => (
            <Onboard
              currentUser={currentUser}
              removeError={removeError}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/mentor/schedule"
          render={(props) => (
            <MentorPage currentUser={currentUser} {...props} />
          )}
        />
        <Route
          exact
          path="/tapit"
          render={(props) => <Tapit currentUser={currentUser} {...props} />}
        />
        <Route
          exact
          path="/signin"
          render={(props) => {
            return (
              <AuthForm
                removeError={removeError}
                errors={errors}
                authUrl={authUrl}
                onAuth={authUser}
                forgot={forgotPassword}
                googleUrl={googleUrl}
                buttonText="Log in"
                heading="Welcome to the Organized Side of Learning"
                ptext="Keep track of things you want to learn or learning tasks you hope to finish here. Don’t forget to tick them off once done."
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path="/signup"
          render={(props) => {
            return (
              <AuthForm
                removeError={removeError}
                errors={errors}
                authUrl={authUrl}
                googleUrl={googleUrl}
                onAuth={authUser}
                forgot={forgotPassword}
                signUp
                buttonText="Sign me Up"
                heading="Accelerate your career growth"
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path="/resetpassword"
          render={(props) => {
            return (
              <AuthForm
                removeError={removeError}
                errors={errors}
                authUrl={authUrl}
                googleUrl={googleUrl}
                onAuth={authUser}
                resetPass={resetPassword}
                reset
                buttonText="Reset"
                heading="Reset your password"
                {...props}
              />
            );
          }}
        />

        <Route path="/enterprise" render={(props) => <EnterpriseLanding />} />

        <Route
          path={"/onboarding"}
          render={(props) => <Onboarding currentUser={currentUser} />}
        />

        <Route
          path={"/onboard-user"}
          render={(props) => <OnboardingUser currentUser={currentUser} />}
        />

        <Route
          path="/learning/:slug"
          render={(props) => <ShareLearningPage />}
        />

        <Route
          path="/:slug"
          render={(props) => (
            <MentorPage currentUser={currentUser} {...props} />
          )}
        />

        <Route
          path="*"
          render={(props) => <Error currentUser={currentUser} {...props} />}
        />
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    currentUserResources: state.currentUserResources,
    errors: state.errors,
    authUrl: state.authUrl,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    authUser,
    removeError,
    googleUrl,
    googleUser,
    forgotPassword,
    resetPassword,
    getResource,
    getUser,
  })(Main)
);
