import React, { Component } from "react";
import Footer from "../components/Footer";
// import { Link } from 'react-router-dom';

import { connect } from "react-redux";

class Tapit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: false,
      time: [],
      error: false,
      onboard: false,
    };
  }

  render() {
    return (
      <div className="mentor-page">
        <iframe
          src="https://google.com"
          width="100%"
          height="500px"
          style="border:none;"
        ></iframe>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(Tapit);
